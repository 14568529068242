@import url('https://fonts.googleapis.com/css2?family=Eczar:wght@400;500;700&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


* {
  outline: none;
}

body {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a746;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #00ffc3;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  
  --breakpoint-xs: 0px;
  --breakpoint-sm: 600px;
  --breakpoint-md: 900px;
  --breakpoint-lg: 1200px;
  --breakpoint-xl: 1800px;
  --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI","Eczar","Roboto Condensed","Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace, "Audiowide", cursive;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #3FBFBF;
  text-align: left;
  background-color: RGBA(54,54,64,100);
}
                            

html {
  font-family: 'Eczar sans-serif';
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.dafiPrimaryhex { color: #3FBFBF; }
.dafiDefaulthex { color: #212626; }
.dafiColorhex1 { color: #3FBFA8; }
.dafiColorhex2 { color: #43D9A2; }
.dafiColorhex3 { color: #41D983; }

/* Color Theme Swatches in RGBA */
.dafiPrimaryrgba { color: rgba(63, 191, 191, 1); }
.dafiDefaultrgba { color: rgba(33, 38, 38, 1); }
.dafiColor3rgba { color: rgba(63, 191, 168, 1); }
.dafiColor4rgba { color: rgba(67, 217, 162, 1); }
.dafiColor5rgba { color: rgba(65, 217, 131, 1); }

/* Color Theme Swatches in HSLA */
.dafiColorPrimaryhsla { color: hsla(180, 50, 49, 1); }
.dafiColorDefaulthsla { color: hsla(180, 7, 13, 1); }
.dafiColor3hsla { color: hsla(169, 50, 49, 1); }
.dafiColor4hsla { color: hsla(158, 66, 55, 1); }
.dafiColor5hsla { color: hsla(146, 66, 55, 1); }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }


